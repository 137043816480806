<div>
    <section class="bg-white dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center min-h-[calc(100vh-64px)] flex">
            <div class="flex justify-between my-auto flex-col md:flex-row"
                >
                <div>
                    <p class="text-7xl md:text-8xl lg:text-9xl font-bold tracking-wider dark:text-gray-300 text-black">404</p>
                    <p class="text-4xl md:text-5xl lg:text-6xl font-bold tracking-wider dark:text-gray-300 text-black mt-2">Page Not
                        Found</p>
                    <p class="text-lg md:text-xl lg:text-2xl text-gray-500 my-5 md:my-12">Sorry, the page you are looking for
                        could not be
                        found.</p>
                        <div class="flex justify-center">
                            <a href="/"
                                class="bg-blue-600 flex hover:bg-blue-700 items-center px-4 py-2 rounded space-x-2 text-gray-100 transition"
                                title="Go Back">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd"
                                        d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <span>Go Back</span>
                            </a>
                        </div>
                </div>
                <div class="md:w-1/2 lg:h-full flex lg:items-end justify-center p-4">
                    <ng-lottie [options]="loaderOptions" />
                </div>
            </div>
        </div>
    </section>
</div>