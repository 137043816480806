export enum ToastType {
    SUCCESS,
    ERROR,
    INFO,
    WARNING,
    // PERMISSION,
    // NOTIFICATION,
}

export interface ToastModel {
    Title?: string;
    Text?: string;
    ToastType?: ToastType;
    RemovePreviousAlerts?: boolean;
    OnActionCallback?: () => void;
    OnHiddenCallback?: () => void;
    OnShownCallback?: () => void;
    OnTapCallback?: () => void;
}
