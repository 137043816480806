/*
    Enum for Module and ModuleURL mapping in ModuleCore table
*/
export enum Modules {
  HOME = "home",
  ORGANIZATION = "organization",
  SERVICES = "services",
  REPORTS = "reports",
  SETTINGS = "settings",
  HELP = "help",
  PRIVACYPOLICY = "privacy-policy",
  TERMSOFSERVICES = "terms-of-services",
  MESSAGEBOARD = "message-board",
  EVENTS = "events",
  USERS = "users",
  REQUESTS = "requests",
  ORDERS = "orders",
  HISTORY = "history",
  STORE = "store",
  CATEGORY = "category",
  ADMIN = "admin",
  CHAT = "chat",
  CUSTOMPAGE = "custom-page",
  USERDEFINEDPAGE = "user-defined-page",
  MODULES = "modules",
  DEPARTMENTS = "departments",
  USERROLES = "user-roles",
}

export enum ModuleOperationEnum {
  READ = "Read",
  CREATE = "Create",
  UPDATE = "Update",
  DELETE = "Delete",
  SHARE = "Share",
}
