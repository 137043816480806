import { inject, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { IGlobalErrorHandlerService } from "./global-error-handler.service.interface";
import { EventEmitterService } from "./event-emitter.service";
import { ToastType } from "../models/view-models/ToastModel";

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandlerService implements IGlobalErrorHandlerService {

  private eventEmitter = inject(EventEmitterService);

  /**
   * Global error handler
   */
  handleError(error: unknown): void {
    // Optional: Log error to the console
    console.error("Error captured by GlobalErrorHandler:", error);

    // Log the error using Sentry
    Sentry.captureException(error);

    this.eventEmitter.showToast({
      ToastType: ToastType.ERROR,
      Title: "Ah oh!",
      Text: "Something went wrong! Please try again in some time!"
    });

    // Optional: Redirect to a global error page
    // this.zone.run(() => this.router.navigate(['/error']));
  }
}
