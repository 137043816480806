import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AnimationOptions, LottieComponent } from "ngx-lottie";

@Component({
  selector: "app-loading-landing-page",
  standalone: true,
  imports: [CommonModule, TranslateModule, LottieComponent],
  templateUrl: "./loading-landing-page.component.html",
  styleUrl: "./loading-landing-page.component.scss",
})
export class LoadingLandingPageComponent {
  showLoadingCommunityPage: boolean = false;
  loaderOptions: AnimationOptions = {
    path: "../../assets/loader-animation.json",
  };
  loaderStyles: Partial<CSSStyleDeclaration> = {
    marginTop: "-60px",
  };
}
